import {
    REQUEST_STRINGS,
    RECEIVE_STRINGS,
    UPDATE_STRINGS,
    ERROR_STRINGS,
    TOGGLE_DEV_STRINGS
} from '../../types'
import {initialFetchState_} from '../../../constants/defaultValues'
import isEmpty from "lodash/isEmpty"
import _set from "lodash/set";
import _isEmpty from "lodash/isEmpty"
import _merge from "lodash/merge"
import _get from "lodash/get"

export default (state = initialFetchState_, action = {}) => {
    switch(action.type) {
        case REQUEST_STRINGS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_STRINGS:
            let allAvailable = {}
            Object.keys(action.payload).map(locale => {
                allAvailable[locale] = _merge(_get(state.data, locale, {}), _get(action.payload, locale, {}))
            })
            return {
                ...state,
                isFetching: false,
                data: allAvailable,
                adminLoaded: action.admin,
                errors: {},
                message: action.message,
            }
        case UPDATE_STRINGS:
            let data = {...state.data}
            const original = {}
            switch(action.updateType) {
                case 'create':
                case 'update':
                    if(_isEmpty(state.original)){
                        action.allowedLocales.map(l => {
                            _set(data, [l, `${action.payload.category_name}.${action.payload.identifier}`], action.payload[l])
                        })
                    }else{
                        action.allowedLocales.map(l => {
                            _set(original, [l, `${action.payload.category_name}.${action.payload.identifier}`], action.payload[l])
                        })
                    }
                    break
                case 'delete':
                    delete data[`${action.payload.category_name}.${action.payload.identifier}`]
                    break
            }
            return {
                ...state,
                data,
                original,
                errors: {}
            }
        case ERROR_STRINGS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        case TOGGLE_DEV_STRINGS:
            const newState = {}
            if(isEmpty(state.original)){
                newState.original = state.data
                newState.data = {}
            }else{
                newState.original = {}
                newState.data = state.original
            }
            return {
                ...state,
                ...newState
            }
        default:
            return state
    }
}