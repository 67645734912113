/*
LocalStorage keys
*/
import {routes} from "./constants";

export const storage = {
    authentication: 'Authorization_' + btoa(window.location.host),
    authenticationAdmin: 'AuthorizationAdmin_' + btoa(window.location.host),
    sidebar: '__sidebar',
    cookie_settings: '__cs',
    product: '__p',
    notifications: '__n',
    uid: '__last_update',
    filter: '__filter'
}

export const defaultLocale = "cs-CZ"

export const defaultSidebar = ''

//cookie disclaimer
export const defaultCookieSettings = {
    is_default: true,
    is_visible: true,
    cookies: {}
}

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch state
export const initialFetchState_ = {
    data: {},
    isFetching: true,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch array state
export const initialFetchArrayState = {
    data: [],
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch sequence state
export const initialSequenceFetchState = {
    data: [],
    isFetching: false,
    errors: {},
    message: ''
}

const adminHomepages = {}
Object.keys(routes).map(route => {
    if(routes[route].hp){
        adminHomepages[route] = {value: route, label: {cs: routes[route].cs.replace(routes.admin_admin.cs, ""), sk: routes[route].sk.replace(routes.admin_admin.sk, "")}}
    }
})

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean': {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    },
    'product-cover-sizes': {
        data: {
            '1': {value: 1, label: 'large'},
            '2': {value: 2, label: 'medium'},
            '3': {value: 3, label: 'small'},
        },
        isFetching: false,
        shouldReload: false
    },
    'email-template-methods': {
        data: {
            'email': {value: "email", label: 'email'},
            'api': {value: "api", label: 'api'},
        },
        isFetching: false,
        shouldReload: false
    },
    'advice-question-status': {
        data: {
            '0': {value: 0, label: 'question-new'},
            '1': {value: 1, label: 'question-in-progress'},
            '2': {value: 2, label: 'question-finished'},
            '3': {value: 3, label: 'question-re-open'},
        },
        isFetching: false,
        shouldReload: false
    },
    'advice-question-status-new': {
        data: {
            '0': {value: 0, label: 'question-new'},
            '1': {value: 1, label: 'question-in-progress'},
            '2': {value: 2, label: 'question-finished'},
        },
        isFetching: false,
        shouldReload: false
    },
    'advice-question-status-in-progress': {
        data: {
            '1': {value: 1, label: 'question-in-progress'},
            '2': {value: 2, label: 'question-finished'},
        },
        isFetching: false,
        shouldReload: false
    },
    'advice-question-status-finished': {
        data: {
            '2': {value: 2, label: 'question-finished'},
            '3': {value: 3, label: 'question-re-open'},
        },
        isFetching: false,
        shouldReload: false
    },
    'advice-question-status-re-open': {
        data: {
            '1': {value: 1, label: 'question-in-progress'},
            '2': {value: 2, label: 'question-finished'},
            '3': {value: 3, label: 'question-re-open'},
        },
        isFetching: false,
        shouldReload: false
    },
    'advice-question-status-contact': {
        data: {
            '0': {value: 0, label: 'question-new'},
            '2': {value: 2, label: 'question-finished'}
        },
        isFetching: false,
        shouldReload: false
    },
    'advice-question-source': {
        data: {
            '0': {value: 0, label: 'question-source-advice'},
            '1': {value: 1, label: 'question-source-contact'},
        },
        isFetching: false,
        shouldReload: false
    },
    'admin-homepages': {
        data: adminHomepages,
        isFetching: false,
        shouldReload: false
    },
    'invoice-types': {
        data: {
            '0': {value: 0, label: 'invoice'},
            '1': {value: 1, label: 'credit-note-1'},
            '2': {value: 2, label: 'credit-note-2'},
        },
        isFetching: false,
        shouldReload: false
    },
    'invoice-modules': {
        data: {
            '0': {value: 0, label: 'elearning'},
            '1': {value: 1, label: 'sms'},
            '2': {value: 1, label: 'landing-pages'},
        },
        isFetching: false,
        shouldReload: false
    },
    'rss-priorities': {
        data: {
            '0': {value: 0, label: 'none'},
            '3': {value: 3, label: 'low'},
            '2': {value: 2, label: 'medium'},
            '1': {value: 1, label: 'high'},
        },
        isFetching: false,
        shouldReload: false
    },
    'payment-methods-voucher': {
        data: {
            'VOUCHER': {value: "VOUCHER", label: 'paid-via-voucher'}
        },
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isLimited: false,
    isFetching: false,
    user: {},
}
